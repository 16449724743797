@use 'sass:math'
@use 'common'

$sandwich-height: (18rem/16)
$halfSandwich-height: ($sandwich-height / 2)

$bar-length: (26rem/16)

$bar-thickness: (2rem/16)
$halfBar-thickness: ($bar-thickness/2)

.wrapper
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	padding: 0
	border: none
	background: transparent
	&:hover
		.sandwich
			span
				&:nth-child(1)
					transform: translate(-50%, -$halfSandwich-height - $halfBar-thickness * 4)
				&:nth-child(3)
					transform: translate(-50%, $halfSandwich-height + $halfBar-thickness * 3)
	&.modalIsOpen
		&:hover
			.sandwich
				span
					background: var(--Navigation-link-color-active)
					&:nth-child(1)
						transform: translate(-50%, -50%) rotate(45deg)
					&:nth-child(3)
						transform: translate(-50%, -50%) rotate(135deg)

.button
	width: $bar-length
	display: flex
	justify-content: flex-start
	align-items: center

.sandwich
	position: relative
	width: $bar-length
	height: $sandwich-height*2
	span
		position: absolute
		top: 50%
		left: 50%
		width: 100%
		height: $bar-thickness
		background: #000
		border-radius: 3px
		transition-property: transform, background-color, opacity
		transition-duration: 300ms
		transition: .2s ease transform
		&:nth-child(1)
			transform: translate(-50%, -$halfSandwich-height - $halfBar-thickness)
		&:nth-child(2)
			transform: translate(-50%, -50%)
		&:nth-child(3)
			transform: translate(-50%, $halfSandwich-height - $halfBar-thickness)
	&.modalIsOpen
		span
			width: (28rem/16)
			&:nth-child(1)
				transform: translate(-50%, -50%) rotate(-45deg)
			&:nth-child(2)
				opacity: 0
				visibility: hidden
			&:nth-child(3)
				transform: translate(-50%, -50%) rotate(45deg)

.title
	margin-left: (17rem/16)
	color: #000
	font-size: 18px
	line-height: math.div(24, 18)
	display: none
	@media screen and ( min-width: common.$break768 )
		display: block
