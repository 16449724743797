@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-arrowDown
		width: 18px
		height: 22px
	&-navigationArrow
		width: 40px
		height: 36px
	&-shoppingBag,
	&-externalLInkArrow
		width: 14px
		height: 14px
	&-facebook,
	&-instagram
		height: 20px
		width: 20px
	&-chevron
		height: .875rem
		width: .5625rem

	&-go
		+normalizeSize(512, 512)
