@use "sass:math"
@use "common"

.wrapper
	color: #FFF
	display: grid
	@media screen and ( min-width: common.$break768 )
		grid-template-columns: repeat(2, 1fr)
	@media screen and ( min-width: common.$break992 )
		grid-template-columns: repeat(4, 1fr)

.column
	padding: var(--Footer-column-padding)
	&.address
		line-height: (20rem/16)
		text-decoration: underline
		&:hover
			text-decoration: none

.email,
.phone
	display: block
	font-size: (22rem/16)
	line-height: math.div(30rem, 16)
	text-decoration: underline
	&:hover
		text-decoration: none

.phone
	display: flex
	align-items: center

.phoneIconWrapper
	width: 20px
	display: inline-block

.phoneIcon
	position: relative
	padding-top: 100%

.openingHours,
.bussinessRegister
	margin-top: 1.5rem
