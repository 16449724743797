@use 'sass:math'
@use 'common'

.title
	margin-bottom: (40rem/16)
	max-width: 900px
	font-size: 24px
	line-height: math.div(28, 24)
	margin-top: (50rem/16)
	@media screen and ( min-width: common.$break768 )
		margin-bottom: (70rem/16)
		margin-top: (63rem/16)
		font-size: 50px
		line-height: math.div(54, 50)
