@use 'sass:math'
@use 'common'

@keyframes translateUp
	100%
		transform: translateY(0)

.wrapper
	display: none
	transform: translateY(100%)
	position: fixed
	width: 100%
	bottom: 0
	right: 0
	left: 0
	z-index: 1
	overflow: hidden
	border-radius: 7px
	@media ( min-width: common.$break768 )
		left: auto
		max-width: var(--Banner-imageWidth)
	&.isVisible
		animation: .3s translateUp forwards
		animation-delay: .3s
		display: block
	&:hover
		.contentIn
			&::after
				visibility: visible
				opacity: .15

.content
	position: relative
	padding-top: math.div(100%, math.div(500, 280))

.contentIn
	&::after
		content: ''
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background: #000
		transition-property: visibility, opacity
		transition-duration: .3s
		opacity: 0
		visibility: hidden

.button
	cursor: pointer
	position: absolute
	z-index: 2
	top: (6rem/16)
	right: (6rem/16)
	width: 28px
	height: 28px
	border: none
	background: transparent
	&::after,
	&::before
		content: ""
		position: absolute
		top: 50%
		left: 50%
		height: 2px
		width: 100%
		background: var(--Banner-buttonBackground)
	&::after
		transform: translate(-50%, -50%) rotate(45deg)
	&::before
		transform: translate(-50%, -50%) rotate(-45deg)
	&:hover
		&::after,
		&::before
			height: 3px

.textWrapper
	padding: 0 16px 35px 16px
	position: absolute
	bottom: 0
	color: var(--Banner-textColor)

.title
	margin-bottom: 6px
	font-weight: 500
	font-size: (18rem/16)
	@media ( min-width: common.$break768 )
		font-size: (22rem/16)

.text
	font-size: (14rem/16)
	@media ( min-width: common.$break768 )
		font-size: (16rem/16)
