@use 'sass:math'
@use 'common'

@keyframes animateModalIn
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes animateModalOut
	0%
		opacity: 1
	100%
		opacity: 0

@keyframes animateModalContentIn
	0%
		transform: translate(-100%)
	100%
		transform: translate(0)

@keyframes animateModalContentOut
	0%
		transform: translate(0)
	100%
		transform: translate(-100%)

.overlay
	z-index: 10
	animation-name: animateModalOut
	animation-duration: .5s
	animation-fill-mode: forwards
	background-color: rgba(0, 0, 0, 0.65)
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	&.isOpen
		animation-name: animateModalIn
		.contentIn
			animation-name: animateModalContentIn

.content
	outline: none
	background-color: transparent

.contentIn
	height: 100vh
	animation-duration: .5s
	animation-fill-mode: forwards
	animation-name: animateModalContentOut
	display: flex
	flex-direction: column
	justify-content: space-between
	overflow: auto
	outline: none
	background: var(--Modal-background)
	position: absolute
	top: 0
	bottom: 0
	padding: 5rem 3rem var(--Navigation-offset-mobile) var(--Navigation-offset-mobile)
	@media screen and ( min-width: common.$break768 )
		padding: 7.3rem 6.4rem 2.5rem 5.2rem

.logoWrapper
	position: fixed
	right: (30rem/16)
	bottom: (30rem/16)
	width: (143em/16)
	max-width: 20vw
	display: none
	@media screen and ( min-width: common.$break480 )
		display: block

.logo
	padding-top: 100%
