@use 'sass:math'
@use 'common'

$line-height: (21rem/16)

.item
	line-height: $line-height
	min-height: (3 * $line-height)
	& + &
		margin-top: 2rem
	a
		text-decoration: underline
		padding: var(--Footer-spacing-vertical) 0
	&:hover
		a
			text-decoration: none

.iconWrapper
	margin-left: 10px
	display: inline-block
	width: 17px

.icon
	display: block
	position: relative
	padding-top: 100%
