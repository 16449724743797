@use 'sass:math'
@use 'common'

.wrapper
	width: 100%
	display: inline-flex
	justify-content: center
	align-items: center
	white-space: nowrap
	cursor: pointer
	padding: 0 1em
	transition-property: border-color, background
	transition-duration: .3s
	&:hover,
	&:focus
		.arrow
			color: #000
	&.variant_primary
		min-height: var(--Button-height-primary)
		background: var(--Button-background-primary)
		border: 1px solid var(--Button-borderColor-primary)
		&:hover,
		&:focus
			background: var(--Button-hover-background-primary)
			border-color: var(--Button-hover-borderColor-primary)
			& .title
				color: var(--Button-hover-text-color-primary)
		&.is_inverted
			background: var(--Button-hover-background-primary)
			border-color: var(--Button-hover-borderColor-primary)
			& .title
				color: var(--Button-hover-text-color-primary)
			&:hover,
			&:focus
				background: var(--Button-background-primary-hover-inverted)
	&.variant_secondary
		min-height: var(--Button-height-secondary)
		background: var(--Button-background-secondary)
		border: 1px solid var(--Button-borderColor-secondary)
		.title
			font-size: (14rem/16)
		&:hover,
		&:focus
			background: var(--Button-hover-background-secondary)
			border-color: var(--Button-hover-borderColor-secondary)
			& .title
				color: var(--Button-hover-text-color-secondary)
	&.buttonTextAlign
		justify-content: space-between

.title
	color: var(--Button-text-color-primary)
	font-size: 1rem
	line-height: normal
	font-weight: 700
	transition-property: color
	transition-duration: .3s

.arrow
	color: #FFF
	margin-left: 30px
	transition-property: color, transform
	transition-duration: .3s
	&.arrowRotate
		transform: rotate(-180deg)
