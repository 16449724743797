@use 'sass:math'
@use 'common'

.wrapper
	display: flex
	justify-content: center
	padding-bottom: 6rem

.text
	max-width: calc(533em/44)
	text-align: center
	font-size: 24px
	line-height: math.div(28, 24)
	a
		text-decoration: underline
		&:hover
			text-decoration: none
	@media screen and ( min-width: common.$break768 )
		font-size: 34px
		line-height: math.div(38, 34)
