@use 'common'

.wrapper
	display: flex
	@media screen and ( min-width: common.$break768 )
		display: block

.item
	display: flex
	align-items: center
	font-size: 1rem
	padding: 0.35em 0
	cursor: pointer
	transition: color .3s
	&:hover
		text-decoration: underline
		color: var(--Navigation-link-color-active)
	& + &
		margin-left: (10rem/16)
		@media screen and ( min-width: common.$break768 )
			margin-left: 0

.title
	display: none
	margin-left: 5px
	@media screen and ( min-width: common.$break768 )
		display: block
