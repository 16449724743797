.wrapper
	max-width: (980rem/16)
	padding: (18rem/16)
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.15)
	border-radius: (4rem/16)
	overflow: hidden
	position: relative
	&::before
		content: ""
		position: absolute
		top: 0
		bottom: 0
		right: 0
		left: 0
		background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%)
		border-radius: (4rem/16)
		pointer-events: none

.content
	overflow: auto
	-ms-overflow-style: none /* IE and Edge */
	scrollbar-width: none /* Firefox */
	&::-webkit-scrollbar
		display: none

.contentIn
	margin: 0 auto
	padding: 0 0.7em
	padding-left: 0

.items
	display: flex
	box-sizing: content-box

.controlsPrevious
	padding-left: 2px
	position: absolute
	top: 50%
	transform: translateY(-50%)
	left: 0
	&.isHidden
		visibility: hidden
		opacity: 0

.controlsNext
	padding-right: 2px
	position: absolute
	top: 50%
	transform: translateY(-50%)
	right: 0
	&.isHidden
		visibility: hidden
		opacity: 0

.endAnchor
	width: 40px
	flex-shrink: 0
