@use 'common'

.wrapper
	width: 100%
	border: none
	background: transparent
	display: flex
	justify-content: space-between
	align-items: center
	cursor: pointer
	border-bottom: var(--Navigation-link-border)
	&:hover
		.arrow
			transform: translateY(3px)
			color: var(--Navigation-link-color-active)
			&.rotateArrow
				transform: rotate(180deg)
		.link
			text-decoration: underline
			@media screen and ( min-width: common.$break768 )
				transform: translateX(.5rem) scale(1) // scale fixes occasional element's size change due to translate
			a
				color: var(--Navigation-link-color-active)

.subLinkWrapper
	transform: translateX(20px)
	width: calc( 100% - 20px)

.link
	font-size: (16rem/16)
	line-height: (24rem/16)
	letter-spacing: 0.015em
	padding: 0.5em 0
	transition-property: transform, color
	transition-duration: .3s
	@media screen and ( min-width: common.$break768 )
		font-size: (18rem/16)
		padding: (17em/16) 0

.arrow
	transition-property: transform
	transition-duration: .3s
	color: #000
	&.rotateArrow
		transform: rotate(180deg)
