@use 'common'

.wrapper
	max-width: 1330px
	margin: 0 auto
	width: 100%
	&.padding-normal
		padding-right: 20px
		padding-left: 20px
		@media screen and ( min-width: common.$break768 )
			padding-right: 40px
			padding-left: 40px
	&.padding-narrow
		padding-right: 10px
		padding-left: 10px
		@media screen and ( min-width: common.$break768 )
			padding-right: 40px
			padding-left: 40px
	&.view-wide
		max-width: 1500px
