@use 'common'
@use 'sass:math'

.wrapper
	width: 100%
	background: var(--RestaurantTag-background)
	color: var(--RestaurantTag-fontColor)
	border-radius: 4px
	display: flex
	justify-content: center
	padding: var(--RestaurantTag-padding)
	font-size: var(--RestaurantTag-fontSize)
	font-weight: 500
