@use 'sass:math'
@use 'common'

.wrapper
	background: var(--Footer-background)

.content
	max-width: 80.625rem
	margin: 0 auto

.group
	line-height: 1.618
	padding: 2.5rem 0
	position: relative
	&.logo
		padding-top: 0
		@media screen and ( min-width: common.$break768 )
			padding: 2.5rem 0
	&.contact
		display: none
		@media screen and ( min-width: common.$break768 )
			display: block

.separator
	position: absolute
	top: 0
	background: var(--Footer-separator-color)
	width: 100%
	height: var(--Footer-separator-thickness)
	display: none
	@media screen and ( min-width: common.$break768 )
		display: flex

.logoWrapper
	width: 150px
	padding: 0 10px

.logo
	position: relative
	padding-top: math.div(100%, math.div(147,40))
