@use 'sass:math'
@use 'common'

.wrapper
	color: #FFF
	display: grid
	@media screen and ( min-width: common.$break768 )
		grid-template-columns: repeat(2, 1fr)
	@media screen and ( min-width: common.$break992 )
		grid-template-columns: repeat(4, 1fr)

.column
	padding: var(--Footer-column-padding)
	&.linkList
		display: none
		@media screen and ( min-width: common.$break768 )
			display: block

.buttons
	max-width: (153rem/16)
	margin-top: 2rem
	div
		width: 100%
		margin-top: .5rem
		&:nth-child(1)
			margin-top: 0
