@use 'sass:math'
@use 'common'

.wrapper
	display: grid
	grid-template-columns: repeat(3, 1fr)
	align-items: center
	padding: (13rem/16) (20rem/16)
	@media screen and ( min-width: common.$break768 )
		padding: (26rem/16) (40rem/16)

.navigationButton
	display: flex
	align-items: center

.logoWrapper
	display: flex
	justify-content: center

.logo
	width: 100px
	@media screen and ( min-width: common.$break768 )
		width: 150px

.logoIn
	position: relative
	padding-top: math.div(100%, math.div(150, 53))

.localeSwitcher
	display: flex
	justify-content: flex-end
	margin-right: 10px
	@media screen and ( min-width: common.$break768 )
		margin-right: 0
