@use 'sass:math'

.title
	cursor: pointer
	padding: var(--Footer-vertical-spacing) 0
	a
		text-decoration: underline
		font-size: 16px
		line-height: math.div(32,16)
	&:hover
		a
			text-decoration: none
