.wrapper
	white-space: nowrap
	font-size: 1rem
	padding: (15rem/16)
	border: 1px solid #e6e6e6
	background-color: transparent
	cursor: pointer
	transition: background-color .3s
	margin-right: (10rem/16)

	&.activeFilter
		color: #c8102e
		border-color: #c8102e
		
	&.hover
	background-color: #f3f3f3