@use 'sass:math'
@use 'common'

.wrapper
	display: flex
	align-items: center
	cursor: pointer
	font-size: (14rem/16)
	line-height: (35rem/16)
	padding: 0.35em 0
	a
		margin-right: 10px
		transition: color .3s
	&:hover
		color: var(--Navigation-link-color-active)
		a
			color: var(--Navigation-link-color-active)
			text-decoration: underline
	@media screen and ( min-width: common.$break768 )
		font-size: 1rem
