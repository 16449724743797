@use 'common'

.sandwichButton
	position: absolute
	top: var(--Navigation-offset-mobile)
	left: calc(var(--Navigation-offset-mobile) - 20px)
	@media screen and ( min-width: common.$break768 )
		top: 2rem
		left: 3.7rem

.primaryLinks
	margin-bottom: (69rem/16)
	border-top: var(--Navigation-link-border)

.secondaryLinks
	margin-bottom: (168rem/16)
