@use 'sass:math'

.locales
	font-size: 18px
	line-height: normal
	text-transform: uppercase
	display: flex

.locale
	cursor: pointer
	&:hover
		text-decoration: underline
