.wrapper
	cursor: pointer
	padding: 0
	width: 20px
	height: 20px
	background: transparent
	border: none
	display: grid
	justify-content: center
	align-items: center

.arrow
	width: 11px
	height: 11px
	border: solid #c8102e
	border-width: 0 2.5px 2.5px 0
	&.next
		transform: rotate(-45deg)
	&.previous
		transform: rotate(135deg)
