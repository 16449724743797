@use 'common'

.wrapper
	display: flex
	flex-direction: column
	color: #FFF
	position: relative
	@media ( min-width: common.$break992 )
		grid-area: auto / span var(--RestaurantPageTileList-item-gridArea)
		&:not(.is_tileWeight_2, .is_tileWeight_4, .is_tileWeight_8)
			.imageWrapper
				height: 100%
			.image
				min-height: 100%
		&.is_tileWeight_2,
		&.is_tileWeight_4,
		&.is_tileWeight_8
			color: #000
			@media ( min-width: common.$break992 )
				.content
					position: relative
					right: auto
					bottom: auto
					left: auto
					display: flex
					flex-direction: column
					flex-grow: 1
					justify-content: space-between
				.image
					&::after
						background: transparent
		&.is_tileWeight_2
			.image
				aspect-ratio: 303 / 365
		&.is_tileWeight_4
			.image
				aspect-ratio: 125 / 73
		&.is_tileWeight_6
			.image
				aspect-ratio: 79 / 50
		&.is_tileWeight_8
			flex-direction: row
			gap: 1.5625rem
			.imageWrapper

				order: 1
				width: 100%
			.image
				aspect-ratio: 953 / 600
			.content
				justify-content: center

.imageWrapper
	cursor: pointer
	border-radius: 0.25rem
	overflow: hidden
	&:hover
		.image
			transform: scale(1.02)

.tags
	position: absolute
	z-index: 1
	margin: .9375rem

.image
	position: relative
	transition: transform .5s ease-out
	aspect-ratio: 429 / 460
	&::after
		content: ""
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.5) 100%)
	@media	( min-width: common.$break480 )
		aspect-ratio: 603 / 460
	@media	( min-width: common.$break992 )
		aspect-ratio: 58 / 75

.content
	position: absolute
	right: 1.25rem
	bottom: 1.25rem
	left: 1.25rem
	display: flex
	flex-direction: column
	justify-content: flex-end

.title
	margin: 0.9375rem 0 0
	padding-bottom: 0.375rem
	font-size: 2.125rem
	line-height: 2.375rem
	&:hover
		text-decoration: underline

.description
	font-size: 1.125rem
	line-height: 1.375rem

.openingHours
	margin-top: 1.25rem

.subItemsWrapper
	font-size: 1.125rem
	line-height: 1.375rem

.subItemsButton
	border: none
	background: transparent
	text-decoration: underline
	margin-top: 1em
	cursor: pointer
	font-size: inherit
	line-height: inherit
	color: inherit
	width: 100%
	display: flex
	gap: .625rem
	align-items: center
	&:hover
		text-decoration: none

.chevron
	display: flex
	font-size: .625rem
	transition-property: transform
	transition-duration: .3s
	transform-origin: center
	&.isOpen
		transform: scaleY(-1)

.subItemsWrapper
	font-size: 1.125rem
	line-height: 1.375rem

.subItemIn
	text-decoration: underline
	&:hover
		text-decoration: none
	&.isEmpty
		height: 1em

.buttons
	margin: 1.375rem 0 0
	display: flex
	align-items: flex-end
	gap: .625rem

.mobileSecondaryButton
	@media ( min-width: common.$break992 )
		display: none

.desktopSecondaryButton
	display: none
	@media ( min-width: common.$break992 )
		display: block

.eshopNotActive
	.imageWrapper
		position: relative
		cursor: default
		&:hover
			.image
				transform: none
		&::after
			content: ""
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			background: #000
			opacity: .6
	.content
		pointer-events: none
