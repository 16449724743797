@use 'common'

.items
	display: grid
	gap: 2.5rem 1.25rem
	margin: 1.875rem 0
	@media	( min-width: common.$break992 )
		margin: 3.125rem 0
	@media	( min-width: common.$break992 )
		margin: 5rem 0
		grid-template-columns: repeat(8, 1fr)
	&.isLokal
		@media ( min-width: common.$break992 )
			grid-template-columns: repeat(4, 1fr)
		@media	( min-width: common.$break1200 )
			grid-template-columns: repeat(6, 1fr)
		@media	( min-width: common.$break1400 )
			grid-template-columns: repeat(8, 1fr)

.subItems
	display: none
	&.showSubItems
		display: block
